<template>
  <div>
    <b-container fluid>
      <b-row
        v-if="banner_image"
        id="banner"
        :style="{ backgroundImage: `url(${getImagePath(banner_image, {width: screen_width})})` }"
        class=""
      >
        <div
          class="w-100 h-100 gradient-backdrop"
          :class="{ 'darker-gradient-backdrop': use_darker_gradient }"
        >
          <b-row>
            <b-col cols="12" class="px-0 mx-0 mt-md-2 mt-lg-0">
              <b-button
                variant="link"
                class="p-0 float-left"
                @click="backButton"
                ><img
                  class="align-top banner-button"
                  src="../../assets/back-icon.svg"
                  @click="backButton"
                  alt="placeholder"
                />
              </b-button>
              <b-button
                v-b-toggle.menu-sidebar
                variant="link"
                class="float-right p-0 pl-3"
              >
                <img
                  src="../../assets/hamburguer-menu-light.svg"
                  class="align-top nav-bar-button-icon banner-button"
                  alt="Logo"
                />
              </b-button>
              <SongActions
                v-if="type === 'song'"
                v-bind:song="asset"
              ></SongActions>
              <ArtistActions
                v-if="type === 'user'"
                v-bind:artist="asset"
              ></ArtistActions>
            </b-col>

            <b-col cols="12" class="text-center text-light mt-md-2 mt-lg-0">
              <h1 class="banner-title" :class="use_thin_title ? 'font-100' : 'font-weight-light'">{{ banner_title }}</h1>
              <h4 class="banner-text">
                <router-link
                  v-if="type === 'song'"
                  class="text-light banner-text"
                  :to="{
                    name: 'ArtistUsername',
                    params: { id: asset.username },
                  }"
                  >{{ asset.username }}</router-link
                >
                <span class="banner-text" v-if="type === 'user'">{{
                  banner_text
                }}</span>
              </h4>
            </b-col>

            <b-container class="container-buttons mt-3 mt-sm-5 mt-md-3 mt-lg-2">
              <b-row class="mt-3 mt-sm-2 mt-md-0 mt-lg-4 pr-0">
                <b-col
                  cols="8"
                  sm="6"
                  md="4"
                  offset="4"
                  offset-sm="6"
                  offset-md="7"
                  offset-lg="8"
                  class="pr-2 pr-md-0 pt-md-1 banner-buttons"
                >
                  <!--<b-button variant="link" class="float-right banner-link">
                    <img
                      class="song-download-button-img"
                      src="../../assets/profile/song/download.svg"
                      alt="placeholder"
                    />
                  </b-button>-->

                  <b-button
                    v-if="asset.liked && type == 'song'"
                    variant="link"
                    @click="unlikeSong"
                    class="float-right banner-link song-action-button"
                  >
                    <img
                      class="song-like-button-img"
                      src="../../assets/red-heart.svg"
                      alt="placeholder"
                    />
                  </b-button>

                  <b-button
                    v-if="!asset.liked && type == 'song'"
                    variant="link"
                    @click="likeSong"
                    class="float-right banner-link song-action-button"
                  >
                    <img
                      class="song-like-button-img"
                      src="../../assets/profile/song/heart.svg"
                      alt="placeholder"
                    />
                  </b-button>


                  <b-button
                    variant="outline-light"
                    v-if="asset.liked && type == 'user'"
                    class="float-right btn-md btn-square bw-2 ml-1 ml-md-3 ml-lg-2 mt-md-3 artist-follow-button artist-follow-button--unfollow"
                    v-bind:disabled="my_user_id === asset.userprofile.user_id"
                    @click="unfollowArtist"
                    >Unfollow</b-button
                  >
                  <button
                    variant="outline-light"
                    v-if="!asset.liked && type == 'user'"
                    v-bind:disabled="my_user_id === asset.userprofile.user_id"
                    class="btn-outline-light float-right btn-md btn-square bw-2 px-4 ml-1 ml-md-3 ml-lg-2 mt-md-3 artist-follow-button"
                    @click="followArtist"
                    >Follow</button
                  >



                  <b-button
                    v-if="type == 'user' && isAuthenticated"
                    variant="link"
                    v-b-modal.comment-artist-profile-modal
                    class="float-right banner-link  mt-md-2 mr-0 mt-1"
                  >
                    <img
                      class="song-comment-button-img mr-xl-1"
                      src="../../assets/profile/song/comment.svg"
                      alt="comment"
                    />
                  </b-button>
                  <b-button
                    v-if="type == 'song' && isAuthenticated"
                    variant="link"
                    @click="$bvModal.show('comment-song-profile-modal' + asset.id)"
                    v-b-modal.comment-song-profile-modal
                    class="float-right banner-link  mt-md-0 mr-1"
                  >
                    <img
                      class="song-comment-button-img"
                      src="../../assets/profile/song/comment.svg"
                      alt="comment"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-container>
          </b-row>
        </div>
      </b-row>

      <!--
      <b-row id="banner" v-else class="image-backdrop" :class="random_colour">
        <b-col cols="12" class="">
          <b-button
            variant="link"
            class="p-0 float-left"
            @click="$router.go(-1)"
            ><img
              class="align-top"
              src="../../assets/back-icon.svg"
              @click="$router.go(-1)"
              alt="placeholder"
            />
          </b-button>
          <SongActions v-if="type === 'song'" v-bind:song="asset"></SongActions>
          <ArtistActions v-if="type === 'user'" v-bind:artist="asset"></ArtistActions>
        </b-col>

        <b-col
          cols="12"
          class="text-center text-light pt-4 pb-4 mx-auto"
        >

          <h1 class="mx-auto font-weight-light">
            {{banner_title}}
          </h1>
          <h4 class="mx-auto font-weight-light">
            <router-link
              class="text-light"
              v-if="type === 'song'"
              :to="{ name: 'ArtistUsername', params: { id: asset.username } }"
              >{{ asset.username }}</router-link
            >
          </h4>

        </b-col>
      </b-row>

      -->
    </b-container>

    <ArtistProfileComment v-bind:artist="asset"></ArtistProfileComment>
    <SongProfileComment v-bind:song="asset"></SongProfileComment>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import SongActions from "../ancillary/song/actions.vue";
import ArtistActions from "../ancillary/artist/actions.vue";
import ArtistProfileComment from "../ancillary/artist/comment.vue";
import SongProfileComment from "../ancillary/artist/song/comment.vue"

export default {
  name: "Banner",
  props: ["asset", "type"],
  components: {
    SongActions,
    ArtistActions,
    ArtistProfileComment,
    SongProfileComment,
  },
  methods: {
    ...mapActions("User", ["like_user", "unlike_user"]),
    ...mapActions("Song", ["like_song", "unlike_song"]),
    followArtist() {
      if (this.isAuthenticated) {
        this.asset.liked = true;
        this.asset.stats.followers += 1;
        this.like_user(this.asset.id);
      } else {
        this.$store.commit('storeNextUrl', this.$route.path)
        this.$bvModal.show("login-modal");
      }
    },
    unfollowArtist() {
      this.asset.liked = false;
      this.asset.stats.followers -= 1;
      this.unlike_user(this.asset.id);

    },
    likeSong() {
      if (this.isAuthenticated) {
        this.asset.liked = true;
        this.asset.stats.likes += 1;
        this.like_song(this.asset.id);
      } else {
        this.$store.commit('storeNextUrl', this.$route.path)
        this.$bvModal.show("login-modal");
      }
    },
    unlikeSong() {
      this.asset.liked = false;
      this.asset.stats.likes -= 1;
      this.unlike_song(this.asset.id);
    },
    backButton() {
      if (this.getPreviousRoute) {
        this.$router.go(-1);
      }
      else {
        this.$router.push({name: 'Home'});
      }
      
    }
  },
  computed: {
    ...mapGetters(['getPreviousRoute']),
    ...mapGetters(["my_user_id", "isAuthenticated"]),
    ...mapGetters("Generic", { random_colour: "getColour" }),
    banner_image() {
      
      

      if (this.type === "song") {
        return this.asset.banner_image;
      } else if (this.type === "user") {
        return this.asset.userprofile.cover_photo;
      }
      return null;
    },
    banner_title() {
      if (this.type === "song") {
        return this.asset.name;
      } else if (this.type === "user") {
        if (this.asset.userprofile.name) {
          return this.asset.userprofile.name;
        } else {
          return this.asset.username;
        }
      }

      return null;
    },
    banner_text() {
      if (this.type === "song") {
        return this.asset.username;
      } else if (this.type === "user") {
        if (this.asset.userprofile.strap_line) {
          return this.asset.userprofile.strap_line;
        } else {
          return "";
        }
      }

      return "";
    },
    use_darker_gradient() {
      if (this.$route.name === "ArtistUsername") {
        return true;
      } else {
        return false;
      }
    },
    use_thin_title() {
      if (this.$route.name === "ArtistUsername") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>

#banner {
  height: 320px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.gradient-backdrop {
  background-color: rgba(0, 0, 0, 0.25);
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
}

.darker-gradient-backdrop {
  background-color: rgba(0, 0, 0, 0.4);
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
}

.banner-button {
  width: 40px;
  height: 40px;
}

.banner-title {
  font-weight: 300;
  font-size: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.font-100 {
  font-weight: 100;
}

.banner-text {
  font-weight: 200;
  font-size: 28px;
}

.container-buttons {
  max-width: 100% !important;
  padding-left: 0px;
  padding-right: 0px;
}

.follow-btn {
  border-width: 2px;
  width: 132px;
  height: 40px;
}

.btn-square {
  border-radius: 0;
}

/* Song */

.song-download-button-img {
  width: 40px;
}

.song-like-button-img {
  width: 43px;
}

.song-comment-button-img {
  width: 40px;
}

.artist-follow-button {
  background-color: transparent;
  border: 2px solid white;
}

.artist-follow-button:hover {
  color: black;
  background-color: white;
}

@media (min-width: 576px) {

  .artist-follow-button {
    width: 99px;
    height: 33px;
  }

  .artist-follow-button--unfollow {
    width: 99px;
    height: 33px;
    line-height: 15px;
  }

  .song-comment-button-img {
    transform: translateY(-12px);
  }
  
}

@media (min-width: 768px) {

  .artist-follow-button {
    font-size: 24px;
    font-weight: 400;
    line-height: 10px;
    height: 40px;
    width: 132px;
    padding-left: 21px !important;
    margin-top: 10px;
    border: 2.5px solid white;
  }


  .banner-buttons { 
    transform: translateX(15px);
  }

  .song-comment-button-img {
    transform: translateY(3px);
  }

}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {

  .banner-buttons { 
    transform: translate(-27px, -9px);
  }

  .banner-title {
    font-weight: 300;
    font-size: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 5px;
    margin-bottom: 0px;
  }

}

@media (max-width: 768px) {
  .gradient-backdrop {
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .banner-title {
    font-weight: 300;
    font-size: 48px;
  }

  .banner-text {
    font-weight: 200;
    font-size: 24px;
  }
}

@media (max-width: 576px) {
  #banner {
    height: 240px;
  }

  .gradient-backdrop {
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .banner-title {
    font-weight: 300;
    font-size: 38px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .banner-text {
    font-weight: 200;
    font-size: 20px;
  }

  .banner-button {
    width: 30px;
    height: 30px;
  }

  .song-download-button-img {
    width: 30px;
  }

  .song-like-button-img {
    width: 33px;
  }

  .song-comment-button-img {
    width: 30px;
    transform: translateY(-10px);
  }

  .artist-follow-button {
    font-size: 15px;
    width: 96px;
    height: 31px;
    line-height: 18px;
  }

  .artist-follow-button--unfollow {
    font-size: 15px;
    width: 96px;
    height: 31px;
    line-height: 15px;
  }

}

@media (max-width: 414px) {
  #banner {
    height: 200px;
  }

  .gradient-backdrop {
    padding-top: 25px;
    padding-left: 25px;
    padding-right: 25px;
  }

  .banner-title {
    font-weight: 300;
    font-size: 28px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .banner-text {
    font-weight: 200;
    font-size: 16px;
  }

  .song-download-button-img {
    width: 25px;
  }

  .song-like-button-img {
    width: 28px;
  }

  .song-comment-button-img {
    width: 27px;
    transform: translateY(-8px);
  }

  .banner-link {
    padding-left: 6px;
    padding-right: 6px;
  }

  .container-buttons {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media (max-width: 375px) {
  .artist-follow-button {
    font-size: 15px;
    font-weight: 400;
    line-height: 10px;
    height: 26px;
    width: 89px;
    padding-left: 21px !important;
    margin-top: 10px;
    border: 2.5px solid white;
  }

  .artist-follow-button--unfollow {
    padding-left: 14px !important;
  }

  .banner-buttons { 
    transform: translateY(-5px);
  }
}

@media (max-width: 320px) {
  .gradient-backdrop {
    padding-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
  }

  .banner-button {
    width: 25px;
    height: 25px;
  }

  .container-buttons {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
</style>

