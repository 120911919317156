<template>
  <b-modal
    :id="'comment-song-modal' + song.id"
    size="lg"
    centered
    hide-footer
    title-sr-only
    content-class="bg-dark text-light text-center"
    header-close-variant="light"
    modal-class="dot-dot-dot-modal"
    title="Login"
    @hide="onClose"
  >
    <template #modal-header="{ close }">
      <b-container fluid>
        <b-row class="text-center">
          <!-- Required for equal spacing -->
          <b-col></b-col>

          <b-col cols="12">
            <img
              src="../../../assets/trackd-icon-colours.svg"
              class="mb-4 float-right mt-2"
              @click="close()"
              height="35"
              alt="Logo"
            />
            <h4 class="font-weight-lighter text-left h2 mt-5 mb-5 mb-md-0">
              Comment on <span style="font-weight: 300;">{{song.name}}</span>
            </h4>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <b-container fluid class="pb-0">
      <b-row>
        <div class="col-11 pl-3">
          <div v-if="success">
            <p class="font-weight-light text-success">
              Comment has been added.
            </p>
          </div>
          <div v-if="error">
            <p class="font-weight-light text-danger">
              There was a problem. please try again.
            </p>
          </div>

          <b-form @submit.stop.prevent="Submit" class="pb-2 mr-3">
            <b-form-group label="Your Comment" label-class="text-left font-weight-light ">
              <b-form-textarea
                v-model="form.comment"
                :state="validateState('comment')"
                class="text-area-input text-left mr-3"
                rows="5"
                placeholder="Add a comment"
              ></b-form-textarea>
            </b-form-group>

            <b-form-group class="mt-3">
              <b-button
                @click="Submit"
                variant="info"
                size="md"
                class="px-3 py-2 btn-rounded float-right text-uppercase"
                >Submit</b-button
              >
              <b-button
                @click="$bvModal.hide('comment-song-modal' + song.id)"
                variant="secondary"
                size="md"
                class="px-3 py-2 mr-3 btn-rounded float-right text-uppercase"
                >Close</b-button
              >
            </b-form-group>
          </b-form>
        </div>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CommentSong",
  props: ["song"],
  components: {},
  mixins: [validationMixin],
  data() {
    return {
      success: false,
      error: false,
      showAPIError: false,
      APIErrorText: null,
      responseMsg: null,
      responseClass: null,
      form: {
        comment: null,
      },
    };
  },
  validations: {
    form: {
      comment: {
        required,
      },
    },
  },
  methods: {
    ...mapActions("Song", ["add_comment"]),
    onClose() {
      this.success = false;
      this.error = false;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    Submit() {
      this.error = false;
      this.success = false;

      this.showAPIError = false;
      this.responseMsg = null;

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      var post_data = {
        song: this.song.id,
        comment: this.form.comment
      }
      
      this.add_comment(post_data)
        .then((res) => {
          res;

          this.song.comments.push({id: null, comment: this.form.comment})

          this.$v.form.$reset();
          this.success = true;
          this.responseMsg = res.data.status;
          this.responseClass = "text-success";
          this.form.comment = null

          setTimeout(() => {
            this.$bvModal.hide('comment-song-modal' + this.song.id);
          }, 2500);
        })
        .catch((error) => {
          //console.log(error);

          if (error.data.errors) {
            this.responseMsg = error.data.errors[0];
            this.responseClass = "text-danger";
          } else if (error.data.non_field_errors) {
            this.responseMsg = error.data.non_field_errors[0];
            this.responseClass = "text-danger";
          } else {
            this.responseMsg = error.data.message;
            this.responseClass = "text-danger";
          }
        });
    },
  },
  mounted() {
    this.success = false;
    this.error = false;
  },
};
</script>

<style>
.dot-dot-dot-modal .modal-content {
  width: 500px;
  margin: auto;
}

.forget-link {
  text-decoration: none;
}
.rect-box {
  border-radius: 0 !important;
}

.text-underline {
  text-decoration: underline;
}
.text-underline:hover {
  text-decoration: none;
}

.btn-rounded {
  border-radius: 10px;
}

.h2 {
  font-weight: 400;
  font-size: 24px;
  line-height: 21px;
}

.confirm-text {
  font-weight: 300;
  font-size: 24px;
  line-height: 21px;
  color: #9b9b9b;
}

.text-input {
  background-color: #222222;
  color: #9b9b9b;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  font-weight: 300;
}

.text-area-input {
  background-color: #0E0E0F;
  color: #9b9b9b;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  font-weight: 300;
}

.form-control:focus {
  background-color: #0E0E0F;
  color: #9b9b9b;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #9b9b9b;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #9b9b9b;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #9b9b9b;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #9b9b9b;
}
</style>
