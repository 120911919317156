<template>
  <div>
    <b-dropdown
      dropright
      menu-class="bg-dark trackd-dropdown-menu text-light px-2 pb-3 mt-5"
      no-caret
      variant="link"
      button-class="p-0"
      class="align-top p-0 pr-0 p-0 float-right"
      style="z-index: 1029;"
    >
      <template #button-content>
        <img
          src="../../../assets/library-menu.svg"
          class="align-top banner-button"
          alt="Logo"
        />
      </template>

      <b-dropdown-header id="dropdown-header-label" class="my-2 pb-4">
        <!--<span
          class="text-light pt-2 mt-2 trackd-dropdown-menu-heading mt-4 pt-5"
          >Options
        </span>-->

        <img
          src="../../../assets/trackd-icon-colours.svg"
          class="float-right filter-trackd-icon"
          height="25"
          alt="Logo"
        />
      </b-dropdown-header>

      <b-dropdown-item-button
        variant="link"
        button-class="py-2"
        class="py-1"
        :to="{ name: 'ArtistUsername', params: { id: song.username } }"
      >
        <img
          src="../../../assets/ancillary/artist.svg"
          class="pr-2 move-up-icon"
          height="20"
          alt="Logo"
        />
        Go To Artist
      </b-dropdown-item-button>

      <b-dropdown-item-button
        variant="link"
        button-class="py-2"
        class="py-1"
        @click="chipin_plus_link()"
        aria-describedby="dropdown-header-label"
        v-if="song.chipin_enabled && song.chipin_plus"
      >
        <img
          src="../../../assets/ancillary/chipin.svg"
          class="pr-2 move-up-icon"
          height="20"
          alt="Logo"
        />
        ChipIn+
      </b-dropdown-item-button>

      <b-dropdown-item-button
        variant="link"
        button-class="py-2"
        class="py-1"
        @click="chipin_link()"
        aria-describedby="dropdown-header-label"
        v-if="song.chipin_enabled && !song.chipin_plus"
      >
        <img
          src="../../../assets/ancillary/chipin.svg"
          class="pr-2 move-up-icon"
          height="20"
          alt="Logo"
        />
        ChipIn
      </b-dropdown-item-button>

      <b-dropdown-item-button
        variant="link"
        button-class="py-2"
        class="py-1"
        @click="$bvModal.show('share-song-modal')"
        v-b-modal.share-song-modal
        aria-describedby="dropdown-header-label"
      >
        <img
          src="../../../assets/ancillary/share.svg"
          class="pr-2 move-up-icon"
          height="20"
          alt="Logo"
        />
        Share
      </b-dropdown-item-button>

      <b-dropdown-item-button
        variant="link"
        button-class="py-2"
        class="py-1"
        v-if="isAuthenticated"
        @click="$bvModal.show('report-song-modal')"
        v-b-modal.song-song-modal
        aria-describedby="dropdown-header-label"
      >
        <img
          src="../../../assets/ancillary/report.svg"
          class="pr-2 move-up-icon"
          height="20"
          alt="Logo"
        />
        Report Song
      </b-dropdown-item-button>

      <b-dropdown-item-button
        variant="link"
        button-class="py-2"
        class="py-1"
        v-if="isAuthenticated && song.user_id === my_user_id"
        @click="$bvModal.show('duplicate-song-modal')"
        v-b-modal.duplicate-artist-modal
        aria-describedby="dropdown-header-label"
      >
        <img
          src="../../../assets/ancillary/duplicate-playlist.svg"
          class="pr-2 move-up-icon"
          height="20"
          alt="Logo"
        />
        Duplicate Song
      </b-dropdown-item-button>

      <b-dropdown-item-button
        variant="link"
        button-class="py-2"
        class="py-1"
        v-if="isAuthenticated"
        @click="$bvModal.show('add-to-playlist-modal')"
        v-b-modal.add-to-playlist-modal
        aria-describedby="dropdown-header-label"
      >
        <img
          src="../../../assets/ancillary/rename-playlist.svg"
          class="pr-2 move-up-icon"
          height="18"
          alt="Logo"
        />
        Add To Playlist
      </b-dropdown-item-button>

      <b-dropdown-item-button
        variant="link"
        button-class="py-2"
        class="py-1"
        v-if="isAuthenticated && song.user_id === my_user_id"
        @click="$bvModal.show('delete-song-modal')"
        v-b-modal.delete-song-modal
        aria-describedby="dropdown-header-label"
      >
        <img
          src="../../../assets/ancillary/delete.svg"
          class="pr-2 move-up-icon"
          height="20"
          alt="Logo"
        />
        Delete
      </b-dropdown-item-button>

      <b-dropdown-item-button
        variant="link"
        button-class="py-2"
        class="py-1"
        @click="$bvModal.show('comment-song-modal' + song.id)"
        aria-describedby="dropdown-header-label"
      >
        <img
          src="../../../assets/ancillary/comment.png"
          class="pr-2 move-up-icon"
          height="20"
          alt="Logo"
        />
        Comment on Song
      </b-dropdown-item-button>
    </b-dropdown>

    <ShareSong v-bind:song="song"></ShareSong>
    <DeleteSong v-bind:song="song"></DeleteSong>
    <ReportSong v-bind:song="song"></ReportSong>
    <DuplicateSong v-bind:id="song.id"></DuplicateSong>
    <AddToPlaylist v-bind:song="song"></AddToPlaylist>
    <CommentSong v-bind:song="song"></CommentSong>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ShareSong from "./share.vue";
import DuplicateSong from "./duplicate.vue";
import DeleteSong from "./confirm.vue";
import ReportSong from "./report.vue";
import AddToPlaylist from "./add-to-playlist.vue";
import CommentSong from "./comment.vue"

export default {
  name: "ActionsDropdown",
  props: ["song"],
  components: {
    ShareSong,
    DuplicateSong,
    DeleteSong,
    ReportSong,
    AddToPlaylist,
    CommentSong,
  },
  methods: {
    chipin_plus_link() {
      if (this.isAuthenticated) {
        if (this.index) {
          this.$router.push("chipin-plus-modal-" + this.song.user_id + "-" + this.index)
        } else {
          this.$router.push("chipin-plus-modal-" + this.song.user_id)
        }
      } else {
        this.$store.commit('storeNextUrl', this.$route.path)
        this.$bvModal.show("login-modal");
      }
    },
    chipin_link() {
      if (this.isAuthenticated) {
      if (this.index) {
        this.$router.push("chipin-modal-" + this.song.user_id + "-" + this.index)
      } else {
        this.$router.push("chipin-modal-" + this.song.user_id)
      }
      } else {
        this.$store.commit('storeNextUrl', this.$route.path)
        this.$bvModal.show("login-modal");
      }
    },
  },
  computed: {
    ...mapGetters(["my_user_id", "isAuthenticated"]),
  },
};
</script>

<style>
ul.dropdown-menu,
.trackd-dropdown-menu {
  border: 1px solid #fff !important;
  width: 289px !important;
  min-width: 289px !important;
  border-radius: 20px !important;
}

.banner-button {
  width: 40px;
  height: 40px;
}

.dropdown-toggle-no-caret {
  padding: 0;
}

.dropdown-header {
  padding: none !important;
}

.filter-trackd-icon {
  position: relative;
  top: -5px;
}

.move-up-icon {
  position: relative;
  top: -2px;
}

button.dropdown-item {
  font-weight: 300;
  color: #fff;
  font-weight: 300;
}

.dropdown-item.filter-link {
  color: #fff;
  font-weight: 300;
}

.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:hover,
.dropdown-item:focus {
  color: #fff;
  font-weight: 300;
  border-radius: 5px;
  background-color: #222222;
}

.trackd-dropdown-menu-heading {
  font-weight: 300;
  font-size: 24px;
  line-height: 21px;
  position: relative;
  bottom: -25px;
}

@media (max-width: 576px) {
  .banner-nav-buttons {
    width: 30px;
  }
  .banner-button {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 375px) {
  .dot-modal-btn > button {
    margin-right: 0px;
    padding-right: 5px;
  }
  .banner-button {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 320px) {
  .banner-nav-buttons {
    width: 30px;
  }
  .dot-modal-btn > button {
    margin-right: 0px;
    padding-right: 5px;
  }

  .banner-button {
    width: 25px;
    height: 25px;
  }
  
  .dot-modal-btn > button > img {
    width: 30px;
  }
}
</style>
