<template>
  <div>
    <b-dropdown
      dropright
      menu-class="bg-dark trackd-dropdown-menu text-light px-2 pb-3 mt-5"
      no-caret
      variant="link"
      class="align-top p-0 pr-0 dot-modal-btn more-btn btn-dots p-0 mb-0"
      style="z-index: 1029;"
    >
      <template #button-content>
        <img
          src="../../../assets/library-menu.svg"
          class="align-top banner-nav-buttons"
          alt="Logo"
        />
      </template>

      <b-dropdown-header id="dropdown-header-label" class="my-2 pb-4">
        <!--<span
          class="text-light pt-2 mt-2 trackd-dropdown-menu-heading mt-4 pt-5"
          >Options
        </span>-->

        <img
          src="../../../assets/trackd-icon-colours.svg"
          class="float-right filter-trackd-icon"
          height="25"
          alt="Logo"
        />
      </b-dropdown-header>

      <b-dropdown-item-button
        variant="link"
        button-class="py-2"
        class="py-1"
        v-b-modal="'chipin-plus-modal-' + artist.id"
        aria-describedby="dropdown-header-label"
        v-if="artist.userprofile.chipin_enabled && artist.chipin_plus && isAuthenticated && my_user_id !== artist.id"
      >
        <img
          src="../../../assets/ancillary/chipin.svg"
          class="pr-2 move-up-icon"
          height="20"
          alt="Logo"
        />
        ChipIn+
      </b-dropdown-item-button>
      <b-dropdown-item-button
        variant="link"
        button-class="py-2"
        class="py-1"
        v-b-modal="'chipin-modal-' + artist.id"
        aria-describedby="dropdown-header-label"
        v-if="artist.userprofile.chipin_enabled && !artist.chipin_plus && isAuthenticated && my_user_id !== artist.id"
      >
        <img
          src="../../../assets/ancillary/chipin.svg"
          class="pr-2 move-up-icon"
          height="20"
          alt="Logo"
        />
        ChipIn
      </b-dropdown-item-button>
      <b-dropdown-item-button
        variant="link"
        button-class="py-2"
        class="py-1"
        v-if="isAuthenticated && my_user_id !== artist.id"
        @click="$bvModal.show('report-artist-modal')"
        v-b-modal.report-artist-modal
        aria-describedby="dropdown-header-label"
      >
        <img
          src="../../../assets/ancillary/report.svg"
          class="pr-2 move-up-icon"
          height="20"
          alt="Logo"
        />
        Report Artist
      </b-dropdown-item-button>
      <b-dropdown-item-button
        variant="link"
        button-class="py-2"
        class="py-1"
        v-if="isAuthenticated"
        @click="$bvModal.show('comment-artist-profile-modal')"
        v-b-modal.comment-artist-profile-modal
        aria-describedby="dropdown-header-label"
      >
        <img
          src="../../../assets/ancillary/comment.png"
          class="pr-2 move-up-icon"
          height="20"
          alt="Logo"
        />
        Comment on Profile
      </b-dropdown-item-button>
      <b-dropdown-item-button
        variant="link"
        button-class="py-2"
        class="py-1"
        @click="$bvModal.show('share-artist-modal')"
        v-b-modal.share-artist-modal
        aria-describedby="dropdown-header-label"
      >
        <img
          src="../../../assets/ancillary/share.svg"
          class="pr-2 move-up-icon"
          height="20"
          alt="Logo"
        />
        Share
      </b-dropdown-item-button>
    </b-dropdown>

    <ShareArtist v-bind:artist="artist"></ShareArtist>
    <ReportArtist v-bind:artist="artist"></ReportArtist>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ShareArtist from "./share.vue";
import ReportArtist from "./report.vue";

export default {
  name: "ActionsDropdown",
  props: ["artist"],
  components: {
    ShareArtist,
    ReportArtist,
  },
  computed: {
    ...mapGetters(["my_user_id", "isAuthenticated"]),
  },
};
</script>

<style>
ul.dropdown-menu,
.trackd-dropdown-menu {
  border: 1px solid #fff !important;
  width: 289px !important;
  min-width: 289px !important;
  border-radius: 20px !important;
}

.btn-dots {
  padding: 0px;
  float: right;
}

.btn-dots > button {
  padding: 0px;
}

.dropdown-header {
  padding: none !important;
}

.filter-trackd-icon {
  position: relative;
  top: -5px;
}

.move-up-icon {
  position: relative;
  top: -2px;
}

button.dropdown-item {
  font-weight: 300;
  color: #fff;
  font-weight: 300;
}

.dropdown-item.filter-link {
  color: #fff;
  font-weight: 300;
}

.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:hover,
.dropdown-item:focus {
  color: #fff;
  font-weight: 300;
  border-radius: 5px;
  background-color: #222222;
}

.trackd-dropdown-menu-heading {
  font-weight: 300;
  font-size: 24px;
  line-height: 21px;
  position: relative;
  bottom: -25px;
}

@media (max-width: 768px) {
  .dot-modal-btn > button > img {
    width: 35px;
    height: 35px;
  }
}

@media (max-width: 576px) {
  .dot-modal-btn > button > img {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 375px) {
  .banner-nav-buttons {
    width: 30px;
  }
  .dot-modal-btn > button {
    margin-right: 0px;
    padding-right: 5px;
  }
}

@media (max-width: 320px) {
  .banner-nav-buttons {
    width: 30px;
  }
  .dot-modal-btn > button {
    margin-right: 0px;
    padding-right: 5px;
  }
}
</style>
